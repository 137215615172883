/** Utility methods for dealing with regular expressions in Javascript. */

export class RegexUtils {
	private static readonly TYPICAL_REGEX_CHARS = '*+?.|[]{\\^$';

	/**
	 * Checks if the given regex is valid.
	 *
	 * @param regex The regex to check.
	 * @returns Whether the regex is valid.
	 */
	public static isRegexValid(regex: string): boolean {
		try {
			new RegExp(regex.trim());
			return true;
		} catch (e) {
			return false;
		}
	}

	/**
	 * Strips off Java regex flags, because they lead to validation errors in JavaScript.
	 *
	 * @param regex The regex to be cleaned.
	 * @returns The cleaned regex.
	 */
	public static removeJavaRegexFlags(regex: string): string {
		return regex.replace(/\(\?-?[gmisxud]\)/g, '');
	}

	/** Returns {@code true} if the given string contains no regex characters (e.g. '*'). */
	public static isPlainString(stringOrRegex: string): boolean {
		return !stringOrRegex.split('').some(char => this.TYPICAL_REGEX_CHARS.includes(char));
	}
}
