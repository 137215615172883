import * as array from 'ts-closure-library/lib/array/array';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import * as dom from 'ts-closure-library/lib/dom/dom';
import * as forms from 'ts-closure-library/lib/dom/forms';
import { TagName } from 'ts-closure-library/lib/dom/tagname';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import * as style from 'ts-closure-library/lib/style/style';
import { StringUtils } from 'ts/commons/StringUtils';
import { tsdom } from 'ts/commons/tsdom';
import { ArrayUtils } from './ArrayUtils';
import { PerspectiveUtils } from './PerspectiveUtils';
import { RegexUtils } from './RegexUtils';

/**
 * Validates user input and displays error and warning messages in a DOM element if the data does not match the given
 * criteria. This class uses the template ts.commons.UIUtilsTemplate.alerts. It expects UIUtilsTemplate.alerts to be
 * rendered on the page.
 *
 * Please note that there is already a new component (ValidationContext.tsx) that should handle the global validation
 * messages in a React way. If possible, use the new React component for rendering!
 */
export class Validator {
	/** Class used for error message elements. */
	public static ERROR_MESSAGE_CLASS = 'error';

	/** Class used for warning message elements. */
	public static WARNING_MESSAGE_CLASS = 'warning';

	/** Class used for info message elements. */
	public static INFO_MESSAGE_CLASS = 'info';

	/** Class used for error message container element. */
	public static ERROR_CONTAINER_CLASS = 'validation-errors';

	/** Class used for warning message container elements. */
	public static WARNING_CONTAINER_CLASS = 'validation-warnings';

	/** Class used for info message container elements. */
	public static INFO_CONTAINER_CLASS = 'validation-infos';

	/** Class used for control group elements. */
	public static CONTROL_GROUP_CLASS = 'control-group';

	/** Whether any errors occurred while using the validation methods. */
	public foundErrors = false;

	/** The element to which error messages are rendered. */
	public errorElement: Element;

	/** The element to which warning messages are rendered. */
	public warningElement: Element;

	/** The element to which info messages are rendered. */
	public infoElement: Element | null;

	/** @param containerElement Optional element that contains the alerts. */
	public constructor(containerElement: Element | null = null) {
		this.errorElement = this.prepareAlert(containerElement, Validator.ERROR_CONTAINER_CLASS);
		this.warningElement = this.prepareAlert(containerElement, Validator.WARNING_CONTAINER_CLASS);
		this.infoElement = this.prepareAlert(containerElement, Validator.INFO_CONTAINER_CLASS);
		this.removeControlGroupHighlights(containerElement);
	}

	/**
	 * Removes the highlights from any control groups on the page.
	 *
	 * @param containerElement Optional element that contains the alerts.
	 */
	private removeControlGroupHighlights(containerElement: Element | null): void {
		const controlGroups = tsdom.getElementsByClass(Validator.CONTROL_GROUP_CLASS, containerElement);
		ArrayUtils.addAllToArray(controlGroups, tsdom.getElementsByClass('input', containerElement));
		for (const controlGroup of controlGroups) {
			this.highlightControlGroup(controlGroup, false);
		}
	}

	/**
	 * Retrieves an alert element. All messages from the alert element are removed.
	 *
	 * @param containerElement Optional element that contains the alerts.
	 * @param alertClass Class name of the alert element. One of 'validation-errors', 'validation-warnings' or
	 *   'validation-infos'.
	 * @param alertMessageClass
	 */
	private prepareAlert(
		containerElement: Element | null,
		alertClass: string,
		alertMessageClass?: string | null
	): Element {
		const alertElement = dom.getElementByClass(alertClass, containerElement);
		asserts.assert(alertElement, 'could not find a suitable alert with class name ' + alertClass);
		this.removeMessages(alertElement, alertMessageClass);
		return alertElement;
	}

	/**
	 * Removes all messages with the given class and hides the container element.
	 *
	 * @param container Element that contains all the alerts.
	 * @param messageType
	 */
	private removeMessages(container: Element, messageType?: string | null): void {
		// Remove all messages. The parent might contain other elements such
		// as headings that should not be removed.
		const oldMessages = tsdom.getElementsByClass('text-message', container);
		for (let i = 0; i < oldMessages.length; i++) {
			if (messageType == null || oldMessages[i]!.dataset.type === messageType) {
				tsdom.removeNode(oldMessages[i]!);
			}
		}
		tsdom.setElementShown(container, false);

		// In case we previously faded out a message, we have to set the opacity to
		// CSS default so we can see the container.
		if (this.infoElement != null) {
			style.setOpacity(this.infoElement, 1);
		}
	}

	/** @returns Whether any errors occurred while using the validation methods. */
	public hasErrors(): boolean {
		return this.foundErrors;
	}

	/**
	 * Adds a message to the given element.
	 *
	 * @param element Element for messages.
	 * @param message The message to append.
	 * @param controlGroup The control group to highlight.
	 */
	private appendMessage(element: Element, message: string, controlGroup?: Element | null): void {
		let dataType;
		if (element === this.warningElement) {
			dataType = Validator.WARNING_MESSAGE_CLASS;
		} else if (element === this.errorElement) {
			dataType = Validator.ERROR_MESSAGE_CLASS;
		} else {
			dataType = Validator.INFO_MESSAGE_CLASS;
		}
		this.foundErrors = true;
		const messageElement = dom.createDom(
			TagName.DIV,
			{
				'data-type': dataType,
				class: 'text-message inline-block'
			},
			' ' + message
		);
		element.appendChild(messageElement);
		tsdom.setElementShown(element, true);
		tsdom.scrollToElementIfNotInViewport(element);
		if (controlGroup != null) {
			this.highlightControlGroup(controlGroup, true);
		}
	}

	/**
	 * Adds an error message to the error element.
	 *
	 * @param errorMessage The error message to append.
	 * @param controlGroup The control group to highlight if an error occurred.
	 */
	public appendError(errorMessage: string, controlGroup?: Element | null): void {
		this.appendMessage(this.errorElement, errorMessage, controlGroup);
	}

	/**
	 * Adds a warning message to the container.
	 *
	 * @param warningMessage The warning message to append.
	 * @param controlGroup The control group which caused the warning. It will be highlighted.
	 */
	public appendWarning(warningMessage: string, controlGroup?: Element): void {
		this.appendMessage(this.warningElement, warningMessage, controlGroup);
	}

	/**
	 * Adds an info message to the container and fades it out after 3 seconds.
	 *
	 * @param infoMessage The info message to append.
	 * @param controlGroup The control group which caused the info. It will be highlighted.
	 */
	public appendInfoWithFadeout(infoMessage: string, controlGroup?: Element): void {
		this.appendInfo(infoMessage, controlGroup);
		setTimeout(() => this.fadeOutMessage(this.infoElement!), 3000);
	}

	/**
	 * Adds an info message to the container.
	 *
	 * @param infoMessage The info message to append.
	 * @param controlGroup The control group which caused the info. It will be highlighted.
	 */
	public appendInfo(infoMessage: string, controlGroup?: Element): void {
		this.appendMessage(this.infoElement!, infoMessage, controlGroup);
	}

	/** Makes the Element fade out with a transition. */
	private fadeOutMessage(container: Element): void {
		events.listen(container, EventType.TRANSITIONEND, () => this.removeMessages(container));
		style.setOpacity(container, 0);
	}

	/**
	 * Either adds or removes an error highlighting to the given control group.
	 *
	 * @param controlGroup The control group to modify.
	 * @param hasErrors Whether to add or remove the error highlighting.
	 */
	public highlightControlGroup(controlGroup: Element, hasErrors: boolean): void {
		if (controlGroup.tagName === 'INPUT') {
			controlGroup = tsdom.getAncestorByClass(controlGroup, 'input');
		} else if (
			!(
				controlGroup.classList.contains('input') ||
				controlGroup.classList.contains(Validator.CONTROL_GROUP_CLASS)
			)
		) {
			controlGroup = tsdom.getAncestorByClass(controlGroup, Validator.CONTROL_GROUP_CLASS);
		}
		controlGroup.classList.toggle('error', hasErrors);
	}

	/**
	 * Scrolls the page or dialog properly to either an error, warning or info element, such that the validator is
	 * visible.
	 *
	 * @param elementType Which type of element to scroll to. Default is Validator.ERROR_MESSAGE_CLASS
	 * @param inDialog True if scrolling is in a dialog otherwise false
	 */
	public scrollTo(elementType = Validator.ERROR_MESSAGE_CLASS, inDialog = false): void {
		let element = this.errorElement;
		if (elementType === Validator.WARNING_MESSAGE_CLASS) {
			element = this.warningElement;
		} else if (elementType === Validator.INFO_MESSAGE_CLASS) {
			element = this.infoElement!;
		}
		style.scrollIntoContainerView(element, PerspectiveUtils.getMainContainer());
		if (inDialog) {
			style.setStyle(element, 'display', 'block');
		}
	}

	/**
	 * Ensures that the value of the given string is not empty.
	 *
	 * @param input The value element or string to validate.
	 * @param fieldName The name of the validated field.
	 * @param controlGroup The control group to highlight if an error occurred.
	 * @returns True if the field was not empty
	 */
	public checkNotEmpty(input: string | string[] | null, fieldName: string, controlGroup?: Element | null): boolean {
		if (
			input == null ||
			array.isEmpty(input) ||
			(typeof input === 'string' && StringUtils.isEmptyOrWhitespace(input))
		) {
			this.appendError(fieldName + ' must not be empty. ', controlGroup);
			return false;
		}
		return true;
	}

	/**
	 * Ensures that the value of the given string is unique.
	 *
	 * @param input The value element or string to validate.
	 * @param existingValues Array of already existing values to which the new text input would be compared.
	 * @param fieldName The name of the validated field.
	 * @param controlGroup The control group to highlight if an error occurred.
	 * @returns True if the field was not empty
	 */
	public checkUnique(
		input: string,
		existingValues: string[],
		fieldName: string,
		controlGroup?: Element | null
	): boolean {
		if (existingValues.includes(input)) {
			this.appendError(fieldName + ' already exists. Please choose a different name. ', controlGroup);
			return false;
		}
		return true;
	}

	/**
	 * Ensures that the given object is not null or undefined.
	 *
	 * @param input The value element or string to validate.
	 * @param fieldName The name of the validated field.
	 * @param controlGroup The control group to highlight if an error occurred.
	 * @returns True if the given input was defined
	 */
	public checkDefined(input: unknown | null | undefined, fieldName: string, controlGroup?: Element): boolean {
		if (input == null) {
			this.appendError(fieldName + ' must be provided. ', controlGroup);
			return false;
		}
		return true;
	}

	/**
	 * Removes all error messages from the error message element.
	 *
	 * @param controlGroup Element containing the control groups that were highlighted.
	 */
	public removeErrorMessages(controlGroup: Element | null = null): void {
		this.removeMessages(this.errorElement, Validator.ERROR_MESSAGE_CLASS);
		this.removeControlGroupHighlights(controlGroup);
	}

	/**
	 * Removes all warning messages from the warning message element.
	 *
	 * @param controlGroup Element containing the control groups that were highlighted.
	 */
	public removeWarningMessages(controlGroup: Element | null = null): void {
		this.removeMessages(this.warningElement, Validator.WARNING_MESSAGE_CLASS);
		this.removeControlGroupHighlights(controlGroup);
	}

	/**
	 * Checks the input box for valid Java Regex. Highlights the element and shows an error message if invalid.
	 *
	 * @param regexElement Element.
	 * @param ignoreJavaRegexFlags Optional used to avoid validation errors, due to non-compatibility.
	 * @returns True if regexElement is valid
	 */
	public validateRegexInputElement(regexElement: Element, ignoreJavaRegexFlags = false): boolean {
		this.removeErrorMessages(regexElement);
		let regexValue = forms.getValue(regexElement) as string;
		if (ignoreJavaRegexFlags) {
			regexValue = RegexUtils.removeJavaRegexFlags(regexValue);
		}
		if (!RegexUtils.isRegexValid(regexValue)) {
			this.appendError('Invalid regular expression', regexElement);
			return false;
		}
		return true;
	}

	/** Resets the validator by clearing all messages and the error status. */
	public reset(): void {
		this.removeErrorMessages();
		this.removeWarningMessages();
		this.foundErrors = false;
	}
}
